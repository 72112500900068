<template>
  <div class="quotedPrice">
    <quote-card :dataList="dataList" :endInquiryId="endInquiryId" :isPadding="false" :isShowArrow="false"
                @clearData="clearData"
                :isShowInput="true" bgColor="white"></quote-card>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import quoteCard from "./component/quoteCard.vue";
import {getQuotedPriceInfo} from '@/api/quitedPrice'

export default {
  components: {quoteCard},
  name: "quotedPrice",
  setup() {
    // const router = useRouter();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      dataList: [],
      endInquiryId: ''
    })

    const getList = () => {
      getQuotedPriceInfo({endInquiryId: state.endInquiryId}).then(res => {
        if (res.result) {
          // res.data.push(res.data[0])
          console.log(res.data)

          res.data.forEach(item => {
            console.log(item.quotedPriceImgs)
            console.log(item)
            if (item.quotedPriceImgs && item.quotedPriceImgs.length) {
              console.log(111)
              item.quotedPriceImgs.forEach(imgItem => {
                imgItem.url = imgItem.img;
              })
            }
          })
          state.dataList = res.data
          // console.log(state.dataList)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const clearData = () => {
      state.dataList = [];
      router.push({
        path: '/quotationDetails',
        query: {
          endInquiryId: state.endInquiryId
        }
      })
    }
    onMounted(() => {
      window.document.title = '填写报价';
      state.endInquiryId = route.query.endInquiryId;
      getList()
    })
    return {
      onMounted,
      clearData,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.quotedPrice {

}
</style>
